import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { TextAndImageSection } from "../components/molecules/TextAndImageSection";
import Person from "../components/Person";
import { LayoutBox } from "../components/atoms/LayoutBox";

// eslint-disable-next-line
export const AboutPageTemplate = ({ textImageSections = [], team }) => {
  return (
    <>
      {textImageSections.map((s, idx) => (
        <div
          className={`section${idx % 2 !== 0 ? " has-background-light-blue" : ""
            }`}
          key={idx}
        >
          <TextAndImageSection
            title={s.title}
            mainText={s.description}
            image={s.image}
            hasAltBackgroundColor={idx % 2 !== 0}
            isReverseOrder={idx % 2 !== 0}
          />
        </div>
      ))}
      <LayoutBox>
        <div className="section">
          <h1
            className="title is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
            style={{
              lineHeight: "1",
            }}
          >
            {team.title}
          </h1>
        </div>
        <div className="section pt-0">
          <h2
            className="subtitle has-text-weight-light is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-centered"
            style={{
              lineHeight: "1.4",
            }}
          >
            {team.description}
          </h2>
        </div>
      </LayoutBox>
      <div className="container is-widescreen">
        <div className="columns is-multiline is-centered ml-1 mr-1">
          {team.people.map((person) => (
            <div
              className="column is-one-third-desktop is-half-tablet"
              key={person.name}
            >
              <Person person={person} key={person.name} />
            </div>
          ))}
        </div>
      </div>
      <LayoutBox>
        <div className="section">
          <h1
            className="title is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
            style={{
              lineHeight: "1",
              padding: "0.25rem",
            }}
          >
            {team.hiringSectionTitle}
          </h1>
        </div>
        <div className="section pt-0">
          <h2
            className="subtitle has-text-weight-light is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-centered"
            style={{
              lineHeight: "1.4",
              padding: "0.25rem",
            }}
          >
            {team.hiringSectionDescription}
          </h2>
        </div>
        <div className="section has-text-centered pt-0">
          <Link
            to="/careers"
            className={`button is-rounded is-medium is-primary`}
          >
            {team.hiringSectionButton}
          </Link>
        </div>
      </LayoutBox>
    </>
  );
};

AboutPageTemplate.propTypes = {
  textImageSections: PropTypes.array,
  team: PropTypes.shape({
    title: PropTypes.string,
    mainText: PropTypes.string,
    image: PropTypes.any,
    hiringSectionTitle: PropTypes.string,
    hiringSectionDescription: PropTypes.string,
    hiringSectionButton: PropTypes.string,
  }),
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      title={post.frontmatter.title}
      description={post.frontmatter.description}
    >
      <AboutPageTemplate
        textImageSections={post.frontmatter.textImageSections}
        team={post.frontmatter.team}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        textImageSections {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          title
          description
        }
        team {
          title
          description
          hiringSectionTitle
          hiringSectionDescription
          hiringSectionButton
          people {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            name
            description
            role
            linkedIn
            github
            email
            website
          }
        }
      }
    }
  }
`;
