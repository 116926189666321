import React from "react";
import { LayoutBox } from "../atoms/LayoutBox";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

export const TextAndImageSection = ({
  title = "",
  mainText = "",
  image,
  hasAltBackgroundColor = false,
  isReverseOrder = false,
  hasPortraitImage = false,
  children,
}) => {
  return (
    <LayoutBox hasAltBackgroundColor={hasAltBackgroundColor}>
      <div
        className={`columns is-vcentered is-centered${
          isReverseOrder ? " is-flex-direction-row-reverse" : ""
        }`}
      >
        <div className={`column is-6${isReverseOrder ? " pl-2" : ""}`}>
          <div
            className={`content ${
              isReverseOrder ? "" : "has-text-right-tablet"
            }`}
          >
            <h1
              className="title is-size-4-mobile is-size-3-tablet is-size-2-widescreen"
              style={{
                lineHeight: "1",
              }}
            >
              {title}
            </h1>
            <h2
              className="subtitle has-text-weight-light is-size-6-mobile is-size-6-tablet is-size-5-widescreen pt-4"
              style={{
                lineHeight: "1.4",
              }}
            >
              {mainText}
            </h2>
            {children}
          </div>
        </div>
        <div
          className={`column ${
            hasPortraitImage ? "is-one-third" : "is-one-half"
          } has-text-centered`}
        >
          {image.extension !== "svg" ? (
            <PreviewCompatibleImage
              imageInfo={{
                image,
                alt: title,
              }}
            />
          ) : (
            <img src={image.publicURL} alt={title} />
          )}
        </div>
      </div>
    </LayoutBox>
  );
};
