import React from "react";

export const roundedBoxShadowBorderRadius = "40px";

export const RoundedBoxShadowBox = ({ children, extraClasses = "" }) => (
  <div
    className={`section is-flex is-justify-content-space-between is-flex-direction-column pb-0 ${extraClasses}`}
    style={{
      boxShadow: "2px 4px 10px #C4C4C4",
      borderRadius: roundedBoxShadowBorderRadius,
      height: "100%",
    }}
  >
    {children}
  </div>
);
