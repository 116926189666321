import React from "react";
import PropTypes from "prop-types";
import { FaLinkedin, FaGithub, FaGlobe, FaAt } from "react-icons/fa";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RoundedBoxShadowBox } from "./atoms/RoundedBoxShadowBox";

const Person = ({ person }) => {
  const image = getImage(person.image) || person.image;

  return (
    <RoundedBoxShadowBox extraClasses="pt-5">
      <div className="columns is-vcentered is-mobile">
        <div className="column is-5">
          <figure className="image">
            {typeof image === "object" ? (
              <GatsbyImage
                imgClassName="is-rounded"
                image={image}
                alt={person.name}
              />
            ) : (
              <img
                src={person.image}
                className="is-rounded"
                alt={person.name}
              />
            )}
          </figure>
        </div>
        <div className="column is-7">
          <div className="card-header-title pl-0 pr-0 pb-0">
            <h1 className="title is-size-6-mobile is-size-5-tablet is-size-4-widescreen">
              {person.name}
            </h1>
          </div>

          <div className="card-header-title pl-0 pr-0 pt-1">
            <h2 className="subtitle is-size-6-mobile is-size-6-tablet is-size-5-widescreen has-text-grey">
              {person.role}
            </h2>
          </div>
        </div>
      </div>
      <div
        className="card-content pl-0 pr-0 pt-0 is-flex-grow-1 has-text-weight-light"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {person.description}
      </div>
      <footer className="card-footer">
        {person.linkedIn && (
          <div className="card-footer-item">
            <a href={person.linkedIn} target="_blank" rel="noopener noreferrer">
              <FaLinkedin size="2em" color="black" />
            </a>
          </div>
        )}
        {person.github && (
          <div className="card-footer-item">
            <a href={person.github} target="_blank" rel="noopener noreferrer">
              <FaGithub size="2em" color="black" />
            </a>
          </div>
        )}
        {person.website && (
          <div className="card-footer-item">
            <a href={person.website} target="_blank" rel="noopener noreferrer">
              <FaGlobe size="2em" color="black" />
            </a>
          </div>
        )}
        {person.email && (
          <div className="card-footer-item">
            <a
              href={`mailto:${person.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaAt size="2em" color="black" />
            </a>
          </div>
        )}
      </footer>
    </RoundedBoxShadowBox>
  );
};

Person.propTypes = {
  person: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    description: PropTypes.string,
    github: PropTypes.string,
    linkedIn: PropTypes.string,
    website: PropTypes.string,
  }),
};

export default Person;
